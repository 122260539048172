import React from "react";
import google from "@/../../public/assets/google.svg";
import apple from "@/../../public/assets/apple.svg";
import Image from "next/image";
import { signInWithRedirect } from "aws-amplify/auth";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "@/Redux/store";
import { Action } from "@reduxjs/toolkit";
import { setLoading } from "@/Redux/loader/loader.slice";

const SocialSignIn = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>();
  const handleSignInClick = async (value: "Amazon" | "Apple" | "Facebook" | "Google") => {
    await signInWithRedirect({
      provider: `${value}`,
      customState: "social-signin",
    });
    dispatch(setLoading(false));
  };

  return (
    <div className="other-signup-wrap">
      <div className="or-signup" style={{ marginTop: "10px" }}>
        <span className="or">or continue with</span>
      </div>
      <div className="other-signup">
        {/* <a href={`${process.env.NEXT_PUBLIC_USERPOOL_DOMAIN}/oauth2/authorize?client_id=${process.env.NEXT_PUBLIC_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${window.location.origin}/${process.env.NEXT_PUBLIC_REDIRECT_URL}`} className="other-signup-icon">
          <Image alt="location" src={google} priority />
        </a> */}
        {/* <a href={`${process.env.NEXT_PUBLIC_USERPOOL_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${window.location.origin}/${process.env.NEXT_PUBLIC_REDIRECT_URL}&response_type=CODE&client_id=${process.env.NEXT_PUBLIC_CLIENT_ID}&scope=email openid phone profile`} className="other-signup-icon"> */}
        <span className="other-signup-icon pointer" onClick={() => handleSignInClick("Google")}>
          <Image alt="location" src={google} priority />
        </span>
        {/* </a> */}
        {/* <a href={`${process.env.NEXT_PUBLIC_USERPOOL_DOMAIN}/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=${window.location.origin}/${process.env.NEXT_PUBLIC_REDIRECT_URL}&response_type=CODE&client_id=${process.env.NEXT_PUBLIC_CLIENT_ID}&scope=email openid phone profile`} className="other-signup-icon"> */}
        <span className="other-signup-icon pointer" onClick={() => handleSignInClick("Apple")}>
          <Image alt="location" src={apple} priority />
        </span>
        {/* </a> */}
      </div>
    </div>
  );
};

export default SocialSignIn;
