import { emailRegex, onlyAlbhabets } from "@/regexValues";
import * as Yup from "yup";
export const contactUs = {
  initialValues: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  },
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required("First name cannot be empty")
      .matches(onlyAlbhabets, "First name must contain only letters and spaces")
      .min(3, "At least three characters are required"),
    lastName: Yup.string()
      .trim()
      .required("Last name cannot be empty")
      .matches(onlyAlbhabets, "Last name must contain only letters and spaces")
      .min(3, "At least three characters are required"),
    email: Yup.string().trim().required("Email cannot be empty").matches(emailRegex, "Invalid email address"),
    phoneNumber: Yup.string()
      .trim()
      .required("Phone number cannot be empty")
      .matches(/^(?:\+?\d{1,3}[\s-]?)?\d{10}$/, "Invalid phone number format"),
    subject: Yup.string().trim().required("Subject cannot be empty"),
    message: Yup.string().trim().required("Message cannot be empty"),
  }),
};
