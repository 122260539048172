import React from "react";
import Image from "next/image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonField from "@/Components/UiComponents/ButtonField";
import CustomLink from "@/Components/Common/CustomeLink/CustomLink";
import TextInputField from "@/Components/UiComponents/TextInputField";
import { useForLogin } from "../Auth/Login/useLogin";
import { useTranslation } from "react-i18next";
import { RootState } from "@/Redux/store";
import { useSelector } from "react-redux";

import SocialSignIn from "@/Components/UiComponents/SocialSignIn";
import { firstLetterCapitalFunc } from "@/Utils/CommonFuncs";
const BannerSection = () => {
  const { loginFormik, responseError, isLoading } = useForLogin();
  const { loading } = useSelector((state: RootState) => state.loader);
  const { t } = useTranslation();

  return (
    <section className="banner-section">
      <Image
        alt="location"
        layout="fill"
        src={`${process.env.NEXT_PUBLIC_ASSET_BUCKET}/banner-bg.svg`}
        className="hero-banner-img"
      />
      <Image
        alt="location"
        layout="fill"
        src={`${process.env.NEXT_PUBLIC_ASSET_BUCKET}/banner-right.svg`}
        className="hero-banner-right"
      />
      <Image
        alt="location"
        layout="fill"
        src={`${process.env.NEXT_PUBLIC_ASSET_BUCKET}/banner-left.svg`}
        className="hero-banner-left"
      />
      <Image
        alt="location"
        layout="fill"
        src={`${process.env.NEXT_PUBLIC_ASSET_BUCKET}/banner-line.svg`}
        className="hero-banner-line"
      />
      <Container>
        <div className="banner-form">
          <form onSubmit={loginFormik.handleSubmit}>
            <Row>
              <h2>{t("cms_text_landing_loginHeader")}</h2>
              <Col md={12}>
                <TextInputField
                  type="text"
                  label="Email / Phone number"
                  required={true}
                  placeholder="Enter your email/phone number"
                  name="userName"
                  id="userName"
                  value={loginFormik?.values?.userName}
                  onChange={loginFormik.handleChange}
                  className="cus-inp"
                  error={loginFormik.touched.userName && !!loginFormik.errors.userName}
                  helperText={loginFormik.touched.userName ? loginFormik.errors.userName : ""}
                  onBlur={loginFormik.handleBlur}
                />
              </Col>
              <Col md={12}>
                <TextInputField
                  type="password"
                  label="Password"
                  required={true}
                  placeholder="Enter your password"
                  name="password"
                  id="password"
                  value={loginFormik?.values?.password}
                  onChange={loginFormik.handleChange}
                  error={loginFormik.touched.password && !!loginFormik.errors.password}
                  helperText={loginFormik.touched.password ? loginFormik.errors.password : ""}
                  className="cus-inp m-0"
                  onBlur={loginFormik.handleBlur}
                />
              </Col>
              {responseError && <p className="form-error-message m-0">{responseError}</p>}
              <Col md={12}>
                <CustomLink className="forgot-pass" link="/auth/forgot-password">
                  {firstLetterCapitalFunc("Forgot password?")}
                </CustomLink>
                <ButtonField
                  label={isLoading || loading ? "Loading..." : "Sign in"}
                  className="sign-in-ban"
                  type="submit"
                  disabled={isLoading || loading}
                />
                <SocialSignIn />
                <p className="ban-policy">
                  By clicking Continue to join or sign in, you agree to OG&apos;s{" "}
                  <CustomLink className="customLink pointer" link="/user-agreement" anotherTab={true}>
                    User Agreement
                  </CustomLink>
                  ,{" "}
                  <CustomLink className="customLink pointer" link="/privacy-policy" anotherTab={true}>
                    Privacy policy
                  </CustomLink>
                  , and{" "}
                  <CustomLink className="customLink pointer" link="/cookies" anotherTab={true}>
                    Cookie Policy
                  </CustomLink>
                  .
                </p>
                <p className="have-account">
                  New to OG?{" "}
                  <CustomLink link="/auth/signup" className="ban-join-now">
                    Join now
                  </CustomLink>
                </p>
              </Col>
            </Row>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default BannerSection;
