import { useFormik } from "formik";
import { login } from "@/Services/Validator/Form/Auth/login";
import { useRouter } from "next/navigation";
import { LoginType } from "@/Types/Auth/AuthType";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "@/Redux/store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { getUser } from "@/Redux/userProfile.slice";
import { getUserData, handleSignIn, resendSignupVerificationCode } from "@/Utils/cognito-auth-functions";
import { CognitoLoginType } from "@/Types/CognitoTypes";
import { signOut } from "aws-amplify/auth";

export const useForLogin = () => {
  // Hooks Declared in this section
  const router = useRouter();
  const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>();
  const [responseError, setResponseError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  // Login form formik function
  const loginFormik = useFormik({
    // eslint-disable-line
    initialValues: login("initialValues") as LoginType,
    validationSchema: login("validationSchema"),
    onSubmit: (values: LoginType) => {
      // eslint-disable-line
      setIsLoading(true);
      handleLogin(values);
    },
  });

  // States Declared in this section

  // Lifecycle Declared in this section

  // FUnctions declared in this section

  const handleLogin = async (values: LoginType) => {
    setResponseError("");
    const userData = await getUserData(); // fetch user if there is already user exist
    if (userData?.sub) {
      localStorage.setItem("currentDashboardRole", "Default");
      localStorage.setItem("userName", userData.sub);
      sessionStorage.removeItem("password");
      dispatch(
        getUser({
          router,
          resource: "get-user",
          token: "",
          profileRole: "Default",
          dispatch,
          fetchDashboard: true,
        })
      ); // after succesful login user fetch from DB
    }
    const loginData: CognitoLoginType = (await handleSignIn(values)) as CognitoLoginType;

    if (loginData) {
      const userData = await getUserData();

      if (loginData?.nextStep?.signInStep === "CONFIRM_SIGN_UP") {
        await resendSignupVerificationCode(String(values?.userName));
        sessionStorage.setItem("email", values?.userName);
        sessionStorage.setItem("password", values?.password);
        userData && (await signOut());
        router.push("/auth/verification");
      } else if (loginData?.name) {
        if (loginData?.message === "User is disabled.") {
          setResponseError("Uh-oh! Your account is disabled. Reach out to support for help.");
        } else {
          setResponseError(loginData?.message ?? "");
        }

        setIsLoading(false);
      } else {
        // const userData = await getUserData()
        if (userData && userData?.sub) {
          localStorage.setItem("currentDashboardRole", "Default");
          localStorage.setItem("userName", userData?.sub);
          sessionStorage.removeItem("password");
          dispatch(
            getUser({
              router,
              resource: "get-user",
              token: "",
              profileRole: "Default",
              dispatch,
              fetchDashboard: true,
            })
          );
        }
      }
    }
  };

  // Lifecycle Declared in this section

  // All Returns marked here
  return {
    loginFormik,
    responseError,
    isLoading,
    handleLogin,
  };
};
