import React from "react";
import aboutImage from "@/../../public/assets/about.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import ButtonField from "@/Components/UiComponents/ButtonField";
import { AboutSectionPropType } from "@/Types/PropsPassTypes/PropsTypes";
import { motion } from "framer-motion";
import { firstLetterCapitalFunc } from "@/Utils/CommonFuncs";

const AboutSection = (props: AboutSectionPropType) => {
  const { t } = useTranslation();
  const { aboutShow, handleAboutShow } = props;

  return (
    <section className="about-section">
      <Container>
        <Row>
          <Col md={12}>
            <div className="about-box">
              <h2>{firstLetterCapitalFunc("About us")}</h2>
              <p>
                {t("cms_text_landing_aboutSection_001")} <br /> <br /> <br /> {t("cms_text_landing_aboutSection_002")}
                <br />
                {aboutShow && (
                  <motion.main
                    initial={{ y: 300, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 300, opacity: 0 }}
                    transition={{
                      type: "spring",
                      stiffness: 100,
                      damping: 26,
                    }}
                  >
                    <br /> {t("cms_text_landing_aboutSection_003")}
                    <br />
                    <br /> {t("cms_text_landing_aboutSection_004")}
                    <br />
                    <br /> {t("cms_text_landing_aboutSection_005")}
                    <br />
                    <br /> {t("cms_text_landing_aboutSection_006")}
                  </motion.main>
                )}
                {
                  <ButtonField
                    onClick={handleAboutShow}
                    className="show-more-btn register-btn pointer "
                    label={`${aboutShow ? "Hide" : "Show More"}`}
                    fit
                  />
                }
              </p>
              {/* <ButtonField label="Learn more" className="register-btn" fit /> */}
              <div className="abt-ful-image">
                <Image alt="location" src={aboutImage} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
