import React from "react";
import userImage from "@/../../public/assets/object1.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "next/image";
import ButtonField from "@/Components/UiComponents/ButtonField";
import { UserSectionTypes } from "@/Types/PropsPassTypes/PropsTypes";
import TabContext from "@mui/lab/TabContext";
import { Tab } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { compAndRecruit, interviewers, jobSeekers } from "@/staticData";
import { useTranslation } from "react-i18next";

const UsersSection = (props: UserSectionTypes) => {
  const { handleSignUp, value, handleChange } = props;
  const { t } = useTranslation();

  return (
    <section className="users-section common-padding">
      <Container fluid>
        <Row className="align-items-start">
          <Col md={5}>
            <div className="users-img">
              <Image
                alt="location"
                loading="lazy"
                src={userImage}
                style={{ width: "100%" }}
                blurDataURL={"/assets/blurdataurl.gif"}
                placeholder="blur"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="users-tab-new">
              <TabContext value={value}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable">
                  <Tab label="Companies & Recruiters" value="1" />
                  <Tab label="Interviewers" value="2" />
                  <Tab label="Job Seekers" value="3" />
                </TabList>
                <TabPanel value="1">
                  <div className="users-text">
                    <h2>{t(compAndRecruit?.headText)}</h2>
                    <p>{t(compAndRecruit?.paraText)}</p>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div className="users-text">
                    <h2>{t(interviewers?.headText)}</h2>
                    <p>{t(interviewers?.paraText)}</p>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  <div className="users-text">
                    <h2>{t(jobSeekers?.headText)}</h2>
                    <p>{t(jobSeekers?.paraText)}</p>
                  </div>
                </TabPanel>
              </TabContext>
            </div>
            <ButtonField onClick={handleSignUp} className="register-btn pointer" label="Register" fit />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UsersSection;
