import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Image from "next/image";
import Logo from "@/../../public/assets/OGlogo.svg";
import ButtonField from "@/Components/UiComponents/ButtonField";
interface ScrollToContactType {
  handleScrollcontact?: () => void;
}
const VisitorHeader = (props: ScrollToContactType) => {
  const { handleScrollcontact } = props;

  return (
    <div>
      <header className="landing-header">
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col xs={6}>
              <div className="ftr-logo">
                <a href="/">
                  <Image alt="Flogo" src={Logo} />
                </a>
              </div>
            </Col>
            <Col xs={6} className="text-end">
              <ButtonField label="Contact us" className="btn landing-contact" onClick={handleScrollcontact} />
            </Col>
          </Row>
        </Container>
      </header>
    </div>
  );
};

export default VisitorHeader;
