import { ButtonFieldType } from "@/Types/CommonComponents/UiComponentsType";
import { Button } from "@mui/material";
import React from "react";

const ButtonField = (props: ButtonFieldType) => {
  const {
    className,
    onClick,
    label,
    type,
    disabled,
    variant,
    fit,
    sx,
    innerComponent,
    stopPropgationNeeded,
    innerComponentLocation,
    isInnerComponentRequired,
  } = props;

  return (
    <Button
      style={{ width: fit ? "fit-content" : "100%" }}
      fullWidth={true}
      className={className}
      type={type ?? "button"}
      sx={sx}
      onClick={
        stopPropgationNeeded
          ? (e: any) => {
              //eslint-disable-line
              e.stopPropagation();
              onClick && onClick();
            }
          : onClick
      }
      disabled={disabled ?? false}
      variant={variant ?? "contained"}
    >
      {innerComponentLocation === "right" && isInnerComponentRequired ? (
        <>
          {label}
          {innerComponent && isInnerComponentRequired && innerComponent}
        </>
      ) : (
        <>
          {innerComponent && isInnerComponentRequired && innerComponent}
          {label}
        </>
      )}
    </Button>
  );
};

export default ButtonField;
