import React from "react";
import VisitorHeader from "@/Components/Common/Headers/VisitorHeader";
import LandingFooter from "@/Components/Common/Footer/LandingFooter";
import BannerSection from "./BannerSection";
import AboutSection from "./AboutSection";
import ApplicationSection from "./ApplicationSection";
import UsersSection from "./UsersSection";
import ContactSection from "./ContactSection";
import { useLanding } from "./useLanding";
import FullScreenLoader from "@/Components/Common/FUllScreenLoader/FullScreenLoader";

const Landing = () => {
  const {
    handleSignUp,
    contactUsFormik,
    value,
    handleChange,
    handleSelectedApplicationUsage,
    selectedApplicationUsage,
    aboutShow,
    handleAboutShow,
    handleScrollcontact,
  } = useLanding();

  return (
    <div>
      <VisitorHeader handleScrollcontact={handleScrollcontact} />
      <BannerSection />
      <AboutSection aboutShow={aboutShow} handleAboutShow={handleAboutShow} />
      <ApplicationSection
        handleSelectedApplicationUsage={handleSelectedApplicationUsage}
        selectedApplicationUsage={selectedApplicationUsage}
      />
      <UsersSection handleSignUp={handleSignUp} value={value} handleChange={handleChange} />
      <ContactSection contactUsFormik={contactUsFormik} />
      <LandingFooter />
      <FullScreenLoader />
    </div>
  );
};

export default Landing;
