import React from "react";
import Chip from "@mui/material/Chip";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { applicationUsage } from "@/staticData";
import { ApplicationUsageTypes } from "@/Types/PropsPassTypes/PropsTypes";
import { useTranslation } from "react-i18next";
import { firstLetterCapitalFunc } from "@/Utils/CommonFuncs";

const ApplicationSection = (props: ApplicationUsageTypes) => {
  const { selectedApplicationUsage, handleSelectedApplicationUsage } = props;
  const { t } = useTranslation();

  return (
    <section className="usages-section common-padding">
      <Container>
        <Row>
          <Col md={12}>
            <div className="usages-box">
              <h2>{firstLetterCapitalFunc("Application usage")}</h2>
              <p>
                {t(applicationUsage?.filter((item, index: number) => index === selectedApplicationUsage)[0].content)}
              </p>
            </div>
            <div className="usages-Chip">
              {applicationUsage?.map((item, index) => {
                return (
                  <span
                    onMouseEnter={() => {
                      handleSelectedApplicationUsage(index);
                    }}
                    key={index}
                  >
                    <Chip label={t(item?.title)} className={`pointer ${item?.color}`} />
                  </span>
                );
              })}
            </div>
            <p className="text-center">
              Join us today and experience a more efficient and authentic hiring process with OG - Only Genuine!
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ApplicationSection;
