import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "next/image";
import conactImg from "@/../../public/assets/bottom-banner.svg";
import conactLeft from "@/../../public/assets/vector1.svg";
import conactRight from "@/../../public/assets/vector2.svg";
import TextInputField from "@/Components/UiComponents/TextInputField";
import ButtonField from "@/Components/UiComponents/ButtonField";
import { FormikProps } from "formik";
import { ContactUsFormTypes } from "@/Types/FormTypes";
import { firstLetterCapitalFunc } from "@/Utils/CommonFuncs";
interface ContactSectionProps {
  contactUsFormik: FormikProps<ContactUsFormTypes>;
}
const ContactSection: React.FC<ContactSectionProps> = ({ contactUsFormik }) => {
  return (
    <section className="contact-section common-padding" id="scrollToContact">
      <Container>
        <div className="contact-form">
          <form onSubmit={contactUsFormik.handleSubmit}>
            <h2>{firstLetterCapitalFunc("Contact us")}</h2>
            <Row className="align-items-center">
              <Col md={6}>
                <TextInputField
                  type="text"
                  label="First Name"
                  required={true}
                  placeholder="Enter Your First Name"
                  name="firstName"
                  id="firstName"
                  value={contactUsFormik.values.firstName}
                  onChange={contactUsFormik.handleChange}
                  className="cus-inp"
                  error={contactUsFormik.touched.firstName && !!contactUsFormik.errors.firstName}
                  helperText={contactUsFormik.touched.firstName ? contactUsFormik.errors.firstName : ""}
                  onBlur={contactUsFormik.handleBlur}
                  maxLength={30}
                />
              </Col>
              <Col md={6}>
                <TextInputField
                  type="text"
                  label="Last Name"
                  required={true}
                  placeholder="Enter Your Last Name"
                  name="lastName"
                  id="lastName"
                  value={contactUsFormik.values.lastName}
                  onChange={contactUsFormik.handleChange}
                  className="cus-inp"
                  error={contactUsFormik.touched.lastName && !!contactUsFormik.errors.lastName}
                  helperText={contactUsFormik.touched.lastName ? contactUsFormik.errors.lastName : ""}
                  onBlur={contactUsFormik.handleBlur}
                  maxLength={30}
                />
              </Col>
              <Col md={6}>
                <TextInputField
                  type="text"
                  label="Email"
                  required={true}
                  placeholder="Enter Your Email"
                  name="email"
                  id="email"
                  value={contactUsFormik.values.email}
                  onChange={contactUsFormik.handleChange}
                  className="cus-inp"
                  error={contactUsFormik.touched.email && !!contactUsFormik.errors.email}
                  helperText={contactUsFormik.touched.email ? contactUsFormik.errors.email : ""}
                  onBlur={contactUsFormik.handleBlur}
                />
              </Col>
              <Col md={6}>
                <TextInputField
                  type="text"
                  label="Phone Number"
                  required={true}
                  placeholder="Enter Your Phone Number"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={contactUsFormik.values.phoneNumber}
                  onChange={contactUsFormik.handleChange}
                  className="cus-inp"
                  error={contactUsFormik.touched.phoneNumber && !!contactUsFormik.errors.phoneNumber}
                  helperText={contactUsFormik.touched.phoneNumber ? contactUsFormik.errors.phoneNumber : ""}
                  onBlur={contactUsFormik.handleBlur}
                />
              </Col>
              <Col md={12}>
                <TextInputField
                  type="text"
                  label="Subject"
                  required={true}
                  placeholder="Enter Subject Here"
                  name="subject"
                  id="subject"
                  value={contactUsFormik.values.subject}
                  onChange={contactUsFormik.handleChange}
                  className="cus-inp"
                  error={contactUsFormik.touched.subject && !!contactUsFormik.errors.subject}
                  helperText={contactUsFormik.touched.subject ? contactUsFormik.errors.subject : ""}
                  onBlur={contactUsFormik.handleBlur}
                  maxLength={250}
                />
              </Col>
              <Col md={12}>
                <TextInputField
                  type="text"
                  label="Messages"
                  required={true}
                  placeholder="Enter Your Message Here"
                  name="message"
                  id="message"
                  value={contactUsFormik.values.message}
                  onChange={contactUsFormik.handleChange}
                  className="cus-inp"
                  rows={3}
                  multiline
                  error={contactUsFormik.touched.message && !!contactUsFormik.errors.message}
                  helperText={contactUsFormik.touched.message ? contactUsFormik.errors.message : ""}
                  onBlur={contactUsFormik.handleBlur}
                  maxLength={500}
                />
              </Col>
              <Col md={12}>
                <ButtonField label="Send" className="contect-btn" fit type="submit" />
              </Col>
            </Row>
          </form>
        </div>
      </Container>
      <Image alt="location" src={conactLeft} className="contect-left" />
      <Image alt="location" src={conactRight} className="contect-right" />
      <Image
        alt="location"
        src={conactImg}
        className="contect-bg"
        style={{ width: "100%" }}
        blurDataURL={"/assets/blurdataurl.gif"}
        placeholder="blur"
        loading="lazy"
      />
    </section>
  );
};

export default ContactSection;
