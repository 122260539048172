import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Image from "next/image";
import FLogo from "@/../../public/assets/dashboardLogo.svg";
import CustomLink from "../CustomeLink/CustomLink";
import { firstLetterCapitalFunc } from "@/Utils/CommonFuncs";

const LandingFooter = () => {
  return (
    <div>
      <footer className="main-footer common-padding pb-0">
        <div className="footer-top">
          <Container>
            <Row>
              <Col md={3}>
                <div className="footer-logo-col">
                  <div className="ftr-logo">
                    <a href="/">
                      <Image alt="Flogo" src={FLogo} priority />
                    </a>
                  </div>
                  {/* <ul> 
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>   */}
                </div>
              </Col>
              <Col md={9} className="text-end">
                <CustomLink className="foot-btn" link="/auth/signup">
                  Register
                </CustomLink>
                <CustomLink className="foot-btn white" link="/auth/signup">
                  {firstLetterCapitalFunc("Join as Interviewer")}
                </CustomLink>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="ftr-btm">
          <Container>
            <Row>
              <Col md={6}>
                <div className="footer-copy">
                  <p>© 2024 OG. All Rights Reserved.</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="ftr-btm-link text-md-end">
                  <ul>
                    <li>
                      <CustomLink className="" link="/faq" anotherTab={true}>
                        FAQ&apos;s
                      </CustomLink>
                    </li>
                    <li>
                      <CustomLink className="" link="/terms-conditions" anotherTab={true}>
                        {firstLetterCapitalFunc("terms & conditions")}
                      </CustomLink>
                    </li>
                    <li>
                      <CustomLink className="" link="/privacy-policy" anotherTab={true}>
                        {firstLetterCapitalFunc("Privacy policy")}
                      </CustomLink>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </div>
  );
};

export default LandingFooter;
