export const specialCharRestrict = /^[A-Za-z0-9 ]+$/; //For not allowing special characters only
export const onlyAlbhabets = /^[A-Za-z ]+$/; //Only alphabets
export const combinedRegex = /^[a-zA-Z\s]*$/; //Alphabets only
export const onlyAlphabetsWithNumbersRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s]*$/;

export const numbersOnlyRegex = /^\d+$/; //Numbers only
//Email validation for invalid email
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
//Invalid website url
export const urlRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
/* eslint-disable @typescript-eslint/no-explicit-any */
export const specialCharacterPattern = /[!@#$%^&*(),.?":{}|<>+\-\\/]/; //Restriction for only special characters

export const regexDecimal = /^(\d*\.?\d*)$/; // Regex to match valid input: numbers and a single decimal point
