import { CustomeLink } from "@/Types/CommonComponents/UiComponentsType";
import { useRouter } from "next/navigation";
import React from "react";

const CustomLink = ({ link, children, className, anotherTab }: CustomeLink) => {
  const router = useRouter();
  const handleClick = () => {
    const currentHost = window.location.hostname;
    const currentPort = window.location.port;
    const privacyPolicyURL = `http://${currentHost}:${currentPort}${link}`;
    window.open(privacyPolicyURL, "_blank");
  };
  return (
    <span className={`${className} pointer`} onClick={() => (anotherTab ? handleClick() : router.push(link))}>
      {children}
    </span>
  );
};

export default CustomLink;
