import {
  // FileInputType,
  TextInputFieldType,
} from "@/Types/CommonComponents/UiComponentsType";
import Image from "next/image";
import React, { useState } from "react";
import eye from "@/../../public/assets/eye.svg";
import eyeCross from "@/../../public/assets/eyeCross.svg";
import { InputAdornment, TextField } from "@mui/material";

const TextInputField = (props: TextInputFieldType) => {
  const {
    name,
    id,
    onChange,
    value,
    onClick,
    onBlur,
    error,
    helperText,
    label,
    required,
    placeholder,
    type,
    className,
    multiline,
    rows,
    maxLength,
    fullWidth,
    variant,
    disabled,
    ref,
    autoFocus,
    autoComplete,
    inputPropsLabel,
    divClassName,
    inputMode,
    customProps,
    disablePaste,
  } = props;
  // States and variables
  const [localType, setLocalType] = useState<string>(type);

  return (
    <div className={divClassName ?? "form-group"}>
      <TextField
        onPaste={(e) => {
          disablePaste && e.preventDefault();
        }}
        ref={ref}
        label={label}
        type={localType}
        placeholder={placeholder}
        className={`form-control adorment ${className}`}
        name={name}
        id={id}
        required={required}
        onChange={onChange}
        InputLabelProps={typeof value === "number" ? { shrink: value != null && value.toString().length > 0 } : {}}
        value={value}
        error={error}
        helperText={helperText}
        onClick={onClick}
        onBlur={onBlur}
        multiline={multiline}
        rows={rows}
        inputProps={{ maxLength: maxLength ?? 40, pattern: type === "number" ? "[0-9]+([.,][0-9]+)?" : null }} // eslint-disable-line
        fullWidth={fullWidth ?? false}
        variant={variant ?? "outlined"}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete={type === "password" ? "new-password" : autoComplete}
        inputMode={inputMode}
        InputProps={{
          ...customProps,
          startAdornment: inputPropsLabel && (
            <InputAdornment
              sx={{
                marginRight: "5px", // Adjust gap
                textDecoration: "inherit", // Inherit text decoration
                fontSize: "14px", // Set font size
                fontWeight: "var(--medium)", // Set font weight
              }}
              position="start"
            >
              {inputPropsLabel}
            </InputAdornment>
          ),
        }}
      />

      {type === "password" ? (
        localType === "password" ? (
          <Image alt="location" src={eye} className="show-pass pointer" onClick={() => setLocalType("text")} priority />
        ) : (
          <Image
            alt="location"
            src={eyeCross}
            className="show-pass pointer"
            onClick={() => setLocalType("password")}
            priority
          />
        )
      ) : null}
    </div>
  );
};

export default TextInputField;
