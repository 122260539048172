import * as Yup from "yup";
/* eslint-disable @typescript-eslint/no-explicit-any */
export const login = (type: string) => {
  if (type === "initialValues") {
    const initialValues = {
      userName: "",
      password: "",
      isRememberMe: false,
    };
    return initialValues;
  }
  if (type === "validationSchema") {
    return Yup.object().shape({
      userName: Yup.string()
        .trim()
        .matches(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))\+?@\S+$|^(\+\d{12,13})$/, //eslint-disable-line
          {
            message:
              "Invalid input. Please provide a valid phone number(with country code like +91 or +912 at start) or email address",
            excludeEmptyString: true,
          }
        )
        .test("phone-format", "Please provide a valid phone number", (value) => {
          // Allow empty strings
          if (!value) return true;

          // Check for valid email format
          const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))\+?@\S+$/; //eslint-disable-line
          if (emailRegex.test(value)) return true;

          // Check for phone numbers without country code
          const phoneWithoutCountryCodeRegex = /^\d{10}$/;
          if (phoneWithoutCountryCodeRegex.test(value)) {
            throw new Yup.ValidationError("Phone number must include the country code");
          }

          // Check for correct length of phone number after country code
          const phoneWithCountryCodeRegex = /^\+\d{12,13}$/;
          if (!phoneWithCountryCodeRegex.test(value)) {
            throw new Yup.ValidationError("Please provide a valid phone number");
          }

          // Debug: Log value to see if it contains emojis

          // Check for emojis
          const emojiRegex =
            /[\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}]/u;
          if (emojiRegex.test(value)) return false;

          return true; // No emojis found
        })
        .required("Email / Phone number cannot be empty"),
      password: Yup.string()
        .trim()
        .min(8, "Must be 8 or more than 8 characters")
        .required("Password field is Required")
        .matches(/\w/, "Please enter valid password"),
    });
  }
};
/* eslint-enable @typescript-eslint/no-explicit-any */
